.rec.rec-arrow {
    font-size: 15px;
}

.rec.rec-arrow:hover:enabled, .rec.rec-arrow:focus:enabled {
    color: #ffff;
    background-color: #00c853;
}

.rec.rec-dot {
    background-color: rgba(0,200,83,.5);
}