
#under-construction-gear{
  /* animation-name: ckw;
  animation-duration: 15.5s; */
  animation: ckw infinite 3s linear;
}
@keyframes ckw {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
